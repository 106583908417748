//
// Tables.less
// Tables for, you guessed it, tabular data
// ----------------------------------------


// BASE TABLES
// -----------------

table {
  max-width: 100%;
  background-color: @tableBackground;
  border-collapse: collapse;
  border-spacing: 0;
}

// BASELINE STYLES
// ---------------

.table {
  width: 100%;
  margin-bottom: @baseLineHeight;
  // Cells
  th,
  td {
    padding: 8px;
    line-height: @baseLineHeight;
    text-align: left;
    vertical-align: top;
    border-top: 1px solid @tableBorder;
  }
  th {
    font-weight: bold;
  }
  // Bottom align for column headings
  thead th {
    vertical-align: bottom;
  }
  // Remove top border from thead by default
  caption + thead tr:first-child th,
  caption + thead tr:first-child td,
  colgroup + thead tr:first-child th,
  colgroup + thead tr:first-child td,
  thead:first-child tr:first-child th,
  thead:first-child tr:first-child td {
    border-top: 0;
  }
  // Account for multiple tbody instances
  tbody + tbody {
    border-top: 2px solid @tableBorder;
  }
}



// CONDENSED TABLE W/ HALF PADDING
// -------------------------------

.table-condensed {
  th,
  td {
    padding: 4px 5px;
  }
}


// BORDERED VERSION
// ----------------

.table-bordered {
  border: 1px solid @tableBorder;
  border-collapse: separate; // Done so we can round those corners!
  *border-collapse: collapsed; // IE7 can't round corners anyway
  border-left: 0;
  .border-radius(4px);
  th,
  td {
    border-left: 1px solid @tableBorder;
  }
  // Prevent a double border
  caption + thead tr:first-child th,
  caption + tbody tr:first-child th,
  caption + tbody tr:first-child td,
  colgroup + thead tr:first-child th,
  colgroup + tbody tr:first-child th,
  colgroup + tbody tr:first-child td,
  thead:first-child tr:first-child th,
  tbody:first-child tr:first-child th,
  tbody:first-child tr:first-child td {
    border-top: 0;
  }
  // For first th or td in the first row in the first thead or tbody
  thead:first-child tr:first-child th:first-child,
  tbody:first-child tr:first-child td:first-child {
    -webkit-border-top-left-radius: 4px;
            border-top-left-radius: 4px;
        -moz-border-radius-topleft: 4px;
  }
  thead:first-child tr:first-child th:last-child,
  tbody:first-child tr:first-child td:last-child {
    -webkit-border-top-right-radius: 4px;
            border-top-right-radius: 4px;
        -moz-border-radius-topright: 4px;
  }
  // For first th or td in the first row in the first thead or tbody
  thead:last-child tr:last-child th:first-child,
  tbody:last-child tr:last-child td:first-child {
    .border-radius(0 0 0 4px);
    -webkit-border-bottom-left-radius: 4px;
            border-bottom-left-radius: 4px;
        -moz-border-radius-bottomleft: 4px;
  }
  thead:last-child tr:last-child th:last-child,
  tbody:last-child tr:last-child td:last-child {
    -webkit-border-bottom-right-radius: 4px;
            border-bottom-right-radius: 4px;
        -moz-border-radius-bottomright: 4px;
  }
}


// ZEBRA-STRIPING
// --------------

// Default zebra-stripe styles (alternating gray and transparent backgrounds)
.table-striped {
  tbody {
    tr:nth-child(odd) td,
    tr:nth-child(odd) th {
      background-color: @tableBackgroundAccent;
    }
  }
}


// HOVER EFFECT
// ------------
// Placed here since it has to come after the potential zebra striping
.table {
  tbody tr:hover td,
  tbody tr:hover th {
    background-color: @tableBackgroundHover;
  }
}


// TABLE CELL SIZING
// -----------------

// Change the columns
table {
  .span1     { .tableColumns(1); }
  .span2     { .tableColumns(2); }
  .span3     { .tableColumns(3); }
  .span4     { .tableColumns(4); }
  .span5     { .tableColumns(5); }
  .span6     { .tableColumns(6); }
  .span7     { .tableColumns(7); }
  .span8     { .tableColumns(8); }
  .span9     { .tableColumns(9); }
  .span10    { .tableColumns(10); }
  .span11    { .tableColumns(11); }
  .span12    { .tableColumns(12); }
  .span13    { .tableColumns(13); }
  .span14    { .tableColumns(14); }
  .span15    { .tableColumns(15); }
  .span16    { .tableColumns(16); }
  .span17    { .tableColumns(17); }
  .span18    { .tableColumns(18); }
  .span19    { .tableColumns(19); }
  .span20    { .tableColumns(20); }
  .span21    { .tableColumns(21); }
  .span22    { .tableColumns(22); }
  .span23    { .tableColumns(23); }
  .span24    { .tableColumns(24); }
}
