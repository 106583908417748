/*doc
---
title: Estilos para imprimir
category: Modulos
---

Documentación...

<div class="alert alert-error">Revisar si funciona correctamente.</div>

*/

@baseLineHeight:      24px;
@baseFontSize:        16px;
@headingsFontFamily:  @boldScout;
@headingsFontWeight:  normal;
@headingsColor:       #000;

html{
	background:@white!important;
}

body{
	font-family: @baseFontFamily;
	background:@white!important; 
}

img{
	margin-bottom: 20px;
	display: block;
}

a{
	color:#000!important;
	text-decoration: none!important;
}

h1 { font-size: @baseFontSize * 3.25; }
h2 { font-size: @baseFontSize * 2.5; }
h3 { font-size: @baseFontSize * 2; }
h4 { font-size: @baseFontSize * 1.25; }
h5 { font-size: @baseFontSize * 1.25; }
h6 { font-size: @baseFontSize * 1.25; }

h1,
h2,
h3,
h4,
h5,
h6{
	margin-bottom: 20px;
}

.page-header {
  padding-bottom: 0;
  margin: 0 0 20px 0;
  border-bottom: none;
}

// Header
#header{
	margin-bottom: 20px;
	padding-bottom: 20px;
	border-bottom:2px solid #333;
}

// Footer
#footer{
	padding-top: 20px;
	border-top:2px solid #333;
}
#pie-inferior #ministerio,
#pie-inferior #espana-cultura,
#pie-inferior #iconos-redes-sociales,
#pie-inferior #enlaces-copy{
	display: none;
}

// Logo
#logo .brand{
	width:130px;
	height:65px;
	display:block;
}
#logo .brand img{
	display: block;
}

// Bloques
.bloque{
	margin-bottom: 20px;
}

// Etiquetas
span.tipo, a.tipo{
	padding: 0;
	text-transform: uppercase;
}

// Thumbnails
.thumbnails{
	margin-left: 0;
	margin-bottom: 20px;
	list-style:none;
	border-bottom: 1px solid @gray;
	.thumbnails{
		margin:0;
		border:none;
	}
	.thumbnails > li{
		padding-top: 0;
		border-top: none;
		margin-bottom: 20px;	
	}
}
.thumbnails > li{
	padding-top: 20px;
	border-top: 1px solid @gray;
	margin-bottom: 20px;
}

// Fichas
#cuerpo-ficha{
	margin-bottom: 20px;
}
.fecha-lugar{
	margin-bottom: 20px;
	*{
		display: inline;
	}
}
#cuerpo-ficha-lateral{
	.field-label-inline{
		.field-label,
		.field-items{
			float: none;
		}
	}
}

// Ficha Obra
#foto-vertical,
#foto-horizontal{
	margin-bottom: 20px;
}
#datos-obra-basicos{
	margin-left: 0;
	list-style:none;
}

ul.thumbnails .biblio .autor, ul.thumbnails .biblio .publicacion {
  display: block;
}

// Partes ocultas
.accesorio,
.pager,
.hideText,
.titulo-bloque .ver_mas,
input,
textarea,
select,
.btn,
.help-block,
.show_more,
.btn-group,
.dropdown-menu,
.enlaces-destacados,
.carousel,
#main.cargando,
#cargandoImg,
.top,
#menu-superior,
#navegacion,
.form-buscar,
#links_explorar,
#btn-buscador,
.field-name-field-exposicion-folletos,
.region-sidebar-first.well,
.paginado-fila,
.items-pagina,
.estilo-fila,
#rowStyle,
.mostrar-ocultar,
#compartir,
.jcarousel-skin-default,
#descargar-ficha-sala,
.sliderContainer,
#cuerpo-ficha-lateral .listado-descargas,
#cuerpo-ficha-lateral .listado-links,
.ver-mas,
.ver-mas-actividades,
#listado-recursos,
.listado-descargas,
.recursos,
.portada_prensa .listado-accesos,
.field-name-field-video-video,
.reproductor,
.jp-audio,
.descarga_audio,
.nav-tabs,
.listado-enlaces,
.listado-accesos,
.acceso,
.anclas,
.pdf,
.txt,
.doc,
.xls,
.ppt,
#menu-descargas,
#menu-relacionados,
.ui-widget,
.ui-datepicker,
#textos-descarga,
#preparar-visita,
#btn-preparar-visita,
.visita-action,
.ajax-progress,
#abecedario,
.social-widgets,
#menu-educacion,
.node-webform,
.webform-client-form,
.webform-confirmation,
.lista-accesos,
.flexslider,
#carousel,
#carrusel-carta,
.vista-carrusel .pager .ajax-progress,
.vista-carrusel .pager-current,
.vista-carrusel .pager-previous,
.vista-carrusel .pager-next,
.vista-carrusel .pager-previous,
.vista-carrusel .pager-next,
.vista-carrusel .pager,
.pagination,
#formulario_buscador,
#hoy-en-el-museo,
#bloque-multimedia,
.region-before-breadcrumbs,
#user-login,
#user-pass,
#user-register-form,
#user-login-form,
#user-profile-form,
#texto-logo,
.breadcrumb,
.alert,
.pie-bloques,
#pie-superior,
.close,
.reproductor,
.deepbanner .rsThumbs,
#relacionados-compartir,
.image_body,
.obra-con-comentario,
#texto-cabecera img,
#header-minisite .brand{
	display:none!important;
}